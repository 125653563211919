(function () {
  'use strict';

  angular
    .module('neo.public.classificacio')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('public.classificacio', {
        url: '/classificacio/{idCampionat}',
        templateUrl: 'public/classificacio/classificacio.tpl.html',
       
        controller: 'classificacioCtrl',
        controllerAs: 'vm',
        resolve: {
          classificacions: function (Classificacio,$stateParams) {
            return Classificacio.query({idCampionat :  $stateParams.idCampionat}).$promise;
          }
        }
      });
  }
}());
